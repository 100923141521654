'use client'

// https://stackoverflow.com/questions/75503853/how-to-get-script-from-termly-to-run-in-jsx-on-nextjs#:~:text=My%20solution%20for%20React%20(Next.js)%20sites%20(with,%7B%20createElement%2C%20FC%2C%20useEffect%20%7D%20from%20'react';

import React, { useEffect } from 'react'
// import { createElement, useEffect } from 'react'

// Components
import SEO from '../components/seo'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

interface DivProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string
}

const Disclaimer: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://app.termly.io/embed-policy.min.js'
    script.async = true
    document.body.appendChild(script)
  }, [])

  // return createElement('div', {
  //   name: 'termly-embed',
  //   className: 'relative py-12 content-container',
  //   'data-id': 'dce17cef-8eb1-4b52-b296-c1724cd8af94',
  //   'data-type': 'iframe',
  // })

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Contact Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article"
      />
      <Header />
      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="flex flex-col gap-y-12">
          <div
            {...{ name: 'termly-embed' }}
            className="content-container relative py-6"
            data-id="82cce2db-3e25-4d95-b88a-252d35c06458"
            data-type="iframe"
          />
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default Disclaimer
