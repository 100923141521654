/* eslint-disable jsx-a11y/anchor-is-valid */
export function Upsell() {
  return (
    <div className="bg-op bg-green-brand bg-opacity-100">
      <div className="mx-auto max-w-7xl px-8 py-12 sm:py-16 lg:flex lg:items-center lg:justify-between lg:px-8">
        <h2 className="text-3xl font-bold tracking-normal text-gray-900 sm:text-4xl">
          Need materials for your next project?
          <br className="hidden sm:block" /> Speak with an expert today.
        </h2>
        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <a
            href="tel:346-396-0016"
            // className="rounded-md bg-orange-brand px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            className="rounded-lg border border-orange-brand bg-orange-brand bg-opacity-90 px-4 py-2 text-lg font-semibold leading-6 text-white hover:bg-opacity-100"
          >
            Speak with a Sales Now
          </a>
        </div>
      </div>
    </div>
  )
}
