// Modules
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { Upsell } from '../components/upsells/ready-mix'

// Assets
import hsctruck from '../images/assets/concrete-mixer.jpg'

const Concrete = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['concrete'])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Ready-Mix Concrete for Concrete Contractors"
        description="Ready-Mix Concrete for Concrete Contractors: Overview of types, uses, and applications of ready-mix concrete."
        name="Solocrete Supply"
        type="article"
      />
      <Header />
      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          <div className="flex flex-col gap-y-8">
            <h1 className="text-3xl font-bold tracking-wide">
              Ready-Mix Concrete for Concrete Contractors
            </h1>
            <img
              className="w-auto rounded-lg object-cover sm:h-96 md:h-96"
              src={hsctruck}
              alt="Rebar"
            />
            <p>
              Ready-mix concrete is a widely used construction material that
              offers consistency, quality, and convenience. Delivered pre-mixed
              in precise formulations, ready-mix concrete is ideal for projects
              requiring specific properties, from standard construction
              applications to high-strength, quick-setting, and decorative uses.
              This page provides an overview of the different types of ready-mix
              concrete, their applications, and how contractors commonly use
              them on various projects.
            </p>
          </div>
          {/* <!-- Types of Ready-Mix Concrete Section --> */}
          <section className="mb-0">
            <h2 className="mb-8 text-2xl font-semibold text-gray-800">
              Types of Ready-Mix Concrete
            </h2>
            <p className="mb-4">
              Ready-mix concrete comes in various formulations, each tailored
              for specific construction needs and structural requirements.
              Common types include:
            </p>
            <ul className="list-inside list-disc space-y-2">
              <li>
                <span className="font-semibold">
                  Standard Ready-Mix Concrete
                </span>{' '}
                – A general-purpose mix for everyday concrete needs.
              </li>
              <li>
                <span className="font-semibold">High-Strength Concrete</span> –
                For applications that require enhanced load-bearing capacity.
              </li>
              <li>
                <span className="font-semibold">Accelerated-Set Concrete</span>{' '}
                – Designed for quick setup and curing times.
              </li>
              <li>
                <span className="font-semibold">Fiber-Reinforced Concrete</span>{' '}
                – Added fibers improve durability and crack resistance.
              </li>
              <li>
                <span className="font-semibold">
                  Self-Consolidating Concrete
                </span>{' '}
                – Flows easily into forms and molds, reducing the need for
                vibration.
              </li>
              <li>
                <span className="font-semibold">Decorative Concrete</span> –
                Customized with colors, textures, or patterns for aesthetic
                projects.
              </li>
            </ul>
          </section>

          {/* <!-- Detailed Descriptions of Each Ready-Mix Concrete Type --> */}
          <section className="mb-0">
            <h2 className="mb-8 text-2xl font-semibold text-gray-800">
              Detailed Ready-Mix Concrete Descriptions and Use Cases
            </h2>

            <div className="grid grid-cols-1 gap-6">
              {/* <!-- Standard Ready-Mix Concrete --> */}
              <div>
                <h3 className="mb-2 text-xl font-semibold">
                  Standard Ready-Mix Concrete
                </h3>
                <p className="mb-2">
                  A versatile, general-purpose mix ideal for basic construction
                  needs. It provides reliable strength and durability suitable
                  for most common concrete applications.
                </p>
                <p>
                  <span className="font-bold">Use Case:</span> Sidewalks,
                  driveways, slabs, and other foundational structures.
                </p>
              </div>

              {/* <!-- High-Strength Concrete --> */}
              <div>
                <h3 className="mb-2 text-xl font-semibold">
                  High-Strength Concrete
                </h3>
                <p className="mb-2">
                  Engineered to achieve higher compressive strength, this
                  concrete mix is ideal for projects requiring superior
                  load-bearing capacity.
                </p>
                <p>
                  <span className="font-bold">Use Case:</span> Structural
                  columns, support beams, and other load-bearing components.
                </p>
              </div>

              {/* <!-- Accelerated-Set Concrete --> */}
              <div>
                <h3 className="mb-2 text-xl font-semibold">
                  Accelerated-Set Concrete
                </h3>
                <p className="mb-2">
                  This quick-setting mix is designed to cure and reach
                  structural strength faster than standard concrete, allowing
                  for rapid project progression.
                </p>
                <p>
                  <span className="font-bold">Use Case:</span> Repairs,
                  emergency work, or projects that require fast turnaround
                  times.
                </p>
              </div>

              {/* <!-- Fiber-Reinforced Concrete --> */}
              <div>
                <h3 className="mb-2 text-xl font-semibold">
                  Fiber-Reinforced Concrete
                </h3>
                <p className="mb-2">
                  Contains added fibers, such as synthetic or steel, that
                  enhance tensile strength and minimize cracking. Ideal for
                  surfaces that experience frequent wear and tear.
                </p>
                <p>
                  <span className="font-bold">Use Case:</span> Industrial
                  floors, pavements, and slabs where durability is a priority.
                </p>
              </div>

              {/* <!-- Self-Consolidating Concrete --> */}
              <div>
                <h3 className="mb-2 text-xl font-semibold">
                  Self-Consolidating Concrete
                </h3>
                <p className="mb-2">
                  A high-flow concrete that spreads easily without the need for
                  vibration, ideal for complex formwork and highly reinforced
                  areas.
                </p>
                <p>
                  <span className="font-bold">Use Case:</span> Complex
                  structures, architectural molds, and high-rebar density
                  applications.
                </p>
              </div>

              {/* <!-- Decorative Concrete --> */}
              <div>
                <h3 className="mb-2 text-xl font-semibold">
                  Decorative Concrete
                </h3>
                <p className="mb-2">
                  This concrete is customized with color pigments, aggregates,
                  or stamping to achieve aesthetic appeal, commonly used in
                  design-focused projects.
                </p>
                <p>
                  <span className="font-bold">Use Case:</span> Patios, walkways,
                  driveways, and interior floors with custom finishes.
                </p>
              </div>
            </div>
          </section>

          {/* <!-- How Concrete Contractors Use Ready-Mix Concrete Section --> */}
          <section className="mb-0">
            <h2 className="mb-8 text-2xl font-semibold text-gray-800">
              How Concrete Contractors Use Ready-Mix Concrete on Projects
            </h2>
            <p className="mb-8">
              Ready-mix concrete’s ease of use and adaptability make it a go-to
              solution for contractors. Here’s how contractors utilize different
              types of ready-mix concrete on various project types:
            </p>

            <ul className="list-inside list-disc space-y-2">
              <li>
                <span className="font-semibold">Foundation Work:</span> Standard
                ready-mix concrete provides consistent strength for slabs,
                footings, and basement floors, delivering reliable support for
                structures.
              </li>
              <li>
                <span className="font-semibold">
                  High-Load Structural Components:
                </span>{' '}
                High-strength ready-mix concrete is essential for constructing
                load-bearing elements such as beams and columns, ensuring
                structural stability.
              </li>
              <li>
                <span className="font-semibold">
                  Quick Repairs and Turnaround Projects:
                </span>{' '}
                Accelerated-set concrete is ideal for time-sensitive projects,
                including road repair and emergency patching work, where
                downtime needs to be minimized.
              </li>
              <li>
                <span className="font-semibold">
                  Enhanced Durability and Surface Strength:
                </span>{' '}
                Fiber-reinforced concrete is used for areas that undergo heavy
                use, such as industrial floors and pavements, to reduce cracking
                and improve lifespan.
              </li>
              <li>
                <span className="font-semibold">
                  Complex and Architecturally Detailed Work:
                </span>{' '}
                Self-consolidating concrete is ideal for complex forms and
                high-rebar density, such as in decorative molds, architectural
                structures, and bridge components.
              </li>
              <li>
                <span className="font-semibold">
                  Aesthetic and Decorative Applications:
                </span>{' '}
                Decorative ready-mix concrete enhances patios, walkways, and
                driveways, providing visual appeal while maintaining strength
                and durability.
              </li>
            </ul>
          </section>

          {/* <!-- Conclusion Section --> */}
          <section className="mb-2">
            {/* <h2 className="mb-8 text-2xl font-semibold text-gray-800">
              Conclusion
            </h2> */}
            <p>
              Ready-mix concrete offers a versatile and dependable solution for
              a wide range of construction projects, from foundational support
              to intricate decorative work. With various formulations available,
              ready-mix concrete allows contractors to achieve specific
              performance and aesthetic goals efficiently and effectively. By
              choosing the right type of ready-mix concrete, contractors can
              ensure quality, durability, and precision across their projects.
            </p>
          </section>
          {/* <div className="flex flex-col gap-y-8">
            <h3 className="text-xl font-semibold">
              {t('about-concrete-partner-header')}
            </h3>
            <p className="text-lg">{t('about-concrete-partner-1')}</p>
            <p className="text-lg">{t('about-concrete-partner-2')}</p>
            <p className="text-lg">{t('about-concrete-partner-3')}</p>
            <p className="text-lg">
              Learn more please visit HSC at{' '}
              <b>
                <a href="https://www.buildwithhsc.com">www.buildwithhsc.com</a>
              </b>
            </p>
          </div> */}
          {/* <div className="flex flex-col gap-y-8">
            <h3 className="text-xl font-medium">{t('about-rvs-header')}</h3>
            <p>{t('about-rvs-text-1')}</p>
            <p>{t('about-rvs-text-2')}</p>
          </div> */}
        </main>
      </div>
      <Upsell />
      <Footer />
    </div>
  )
}

export default Concrete
