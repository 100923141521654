// @ts-check

// Modules
import emailjs from '@emailjs/browser'
import { useRef, RefObject } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Button } from '../components/button'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
const formClasses =
  'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm'

const ContactUs = (): JSX.Element => {
  // Ref Hook
  const form = useRef() as RefObject<HTMLFormElement>

  // Translation Hook
  const { t } = useTranslation(['contactus'])

  // Event Handler
  const sendEmail = (e: { preventDefault: () => void }) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_hw0h48j',
        'contact_form',
        form.current!,
        'D5nxn7O6q4JS2Nue5',
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        },
      )
  }

  return (
    <div className="min-h-screen">
      <SEO
        title="Contact Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article"
      />
      <Header />
      <div className="mx-auto w-full px-4 lg:max-w-7xl lg:px-8">
        <main className="flex flex-col gap-x-6 lg:flex-row lg:gap-y-8">
          {/* <div className="mt-12 flex w-full flex-col gap-x-4 gap-y-8 lg:my-12 lg:mr-16 lg:w-3/5"> */}
          <div className="mt-12 flex w-full flex-col gap-x-4 gap-y-8 lg:my-12 lg:mr-16 lg:w-full">
            <h1 className="text-3xl font-semibold tracking-wide">
              {t('contact-us')}
            </h1>
            <div className="font-normal text-gray-700">{t('mission')}</div>
            <div className="border-b border-slate-300 lg:my-4"></div>
            <h3 className="text-lg font-semibold">{t('our-hq')}</h3>
            <div className="flex flex-col gap-y-2">
              <p className="font-medium">{t('location')}</p>
              <p className="font-normal text-gray-700">
                25700 Interstate 45 N<br />
                Suite 475
                <br />
                Spring, TX 77386
                <br />
                <a href="tel:346-396-0016">346-396-0318</a>
              </p>
            </div>
            <div className="border-b border-slate-300 lg:my-4"></div>
            <h3 className="text-lg font-semibold">{t('email-us')}</h3>
            <div className="mb-12 flex flex-col gap-y-6 lg:flex-row">
              <div className="w-full lg:w-1/2">
                <p className="font-medium">{t('careers')}</p>
                <p className="font-normal text-gray-700">
                  <a href="mailto:careers@solocretesupply.com">
                    jobs@solocretesupply.com
                  </a>
                </p>
              </div>
              <div className="w-full lg:w-1/2">
                <p className="font-medium">{t('more-info')}</p>
                <p className="font-normal text-gray-700">
                  <a href="mailto:press@solocretesupply.com">
                    info@solocretesupply.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* <div className="lg:w-2/5">
            <form
              id="contact-form"
              className="my-10 grid grid-cols-1 gap-x-6 gap-y-8 rounded border border-slate-300 p-8"
              ref={form}
              onSubmit={sendEmail}
            >
              <input type="hidden" name="contact_number" />
              <div>
                <label className="mb-3 block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input type="text" name="user_name" className={formClasses} />
              </div>
              <div>
                <label className="mb-3 block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input type="email" name="user_email" className={formClasses} />
              </div>
              <div>
                <label className="mb-3 block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea name="message" className={formClasses}></textarea>
              </div>
              <Button
                type="submit"
                variant="contact"
                // color="blue"
                className="w-full"
                style={{ backgroundColor: '#f15025' }}
              >
                <span>
                  {t('send')}
                </span>
              </Button>
            </form>
          </div> */}
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs

// // @ts-check

// // Modules
// import emailjs from '@emailjs/browser';
// import { useRef, RefObject } from 'react';
// import { useTranslation } from 'react-i18next';

// // Components
// import SEO from '../components/seo'
// import { Button } from '../components/button'
// import { Header } from '../components/header'
// import { Footer } from '../components/footer'

// // Assets
// const formClasses =
//   'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm'

// const ContactUs = (): JSX.Element => {
//   // Ref Hook
//   const form = useRef()  as RefObject<HTMLFormElement>;

//   // Translation Hook
//   const { t } = useTranslation(["contactus"]);

//   // Event Handler
//   const sendEmail = (e: { preventDefault: () => void; }) => {
//     e.preventDefault();

//     emailjs.sendForm('service_hw0h48j', 'contact_form', form.current!, 'D5nxn7O6q4JS2Nue5')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//   };

//   return (
//     <div className='min-h-screen'>
//       <SEO
//         title="Contact Solocrete Supply"
//         description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
//         name="Solocrete Supply"
//         type="article" />
//       <Header />
//       <div className="mx-auto w-full px-4 lg:max-w-7xl lg:px-8">
//         <main className="flex flex-col lg:flex-row gap-x-6 lg:gap-y-8">
//           <div className="w-full lg:w-3/5 flex flex-col mt-12 lg:my-12 gap-y-8 gap-x-4 lg:mr-16">
//             <h1 className='text-3xl tracking-wider font-semibold'>
//               {t("contact-us")}
//             </h1>
//             <div className='font-normal text-gray-700'>
//               {t("mission")}
//             </div>
//             <div className='lg:my-4 border-b border-slate-300'></div>
//             <h3 className='text-lg font-semibold'>
//               {t("our-hq")}
//             </h3>
//             <div className="flex flex-col gap-y-2">
//             <p className='font-medium'>
//               {t("location")}
//             </p>
//             <p className='font-normal text-gray-700'>
//               {/* Havenwood Office Park<br/> */}
//               25700 Interstate 45 N<br/>
//               Suite 475<br/>
//               Spring, TX 77386<br/>
//             </p>
//             </div>
//             <div className='lg:my-4 border-b border-slate-300'></div>
//             <h3 className='text-lg font-semibold'>
//               {t("email-us")}
//             </h3>
//             <div className="flex flex-col lg:flex-row gap-y-6">
//               <div className="w-full lg:w-1/2">
//                 <p className='font-medium'>
//                   {t("careers")}
//                 </p>
//                 <p className='font-normal text-gray-700'>
//                   <a href="mailto:careers@solocretesupply.com">jobs@solocretesupply.com</a>
//                 </p>
//               </div>
//               <div className="w-full lg:w-1/2">
//                 <p className='font-medium'>
//                   {t("more-info")}
//                 </p>
//                 <p className='font-normal text-gray-700'>
//                   <a href="mailto:press@solocretesupply.com">info@solocretesupply.com</a>
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className='lg:w-2/5'>
//             <form id="contact-form" className="my-10 grid grid-cols-1 gap-x-6 gap-y-8 rounded border border-slate-300 p-8" ref={form} onSubmit={sendEmail}>
//               <input type="hidden" name="contact_number" />
//               <div>
//               <label className="mb-3 block text-sm font-medium text-gray-700">Name</label>
//               <input type="text" name="user_name" className={formClasses} />
//               </div>
//               <div>
//               <label className="mb-3 block text-sm font-medium text-gray-700">Email</label>
//               <input type="email" name="user_email" className={formClasses} />
//               </div>
//               <div>
//               <label className="mb-3 block text-sm font-medium text-gray-700">Message</label>
//               <textarea name="message" className={formClasses}></textarea>
//               </div>
//               <Button type="submit" variant="solid" color="blue" className="w-full">
//                 <span>
//                   {t("send")} <span aria-hidden="true">&rarr;</span>
//                 </span>
//               </Button>
//             </form>
//           </div>
//         </main>
//       </div>
//       <Footer />
//     </div>
//   )
// }

// export default ContactUs
