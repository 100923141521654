// Modules
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
// import { Button } from '../components/button'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import safetysupplies from '../images/assets/safetysupplies.jpg'

const SafetySupplies = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['safetysupplies'])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Safety Supplies for Concrete Contractors"
        description="Safety Supplies for Concrete Contractors: Gloves, Vests, Flags, Goggles, Traffic Cones, and more. Learn about types, sizes, and use cases in concrete projects."
        name="Solocrete Supply: Rebar And Accessories"
        type="article"
      />
      <Header />

      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          {/* <!-- Page Title --> */}
          <h1 className="mb-0 text-3xl font-bold">
            Safety Supplies for Concrete Contractors
          </h1>
          <img
            className="w-auto rounded-lg object-cover sm:h-96 md:h-96"
            src={safetysupplies}
            alt="Safety Supplies"
          />
          <p className="mb-0">
            Safety supplies are essential for ensuring the well-being of
            concrete contractors and maintaining a secure job site. From
            protective gloves and hard hats to visibility-enhancing vests and
            traffic control items, these supplies play a crucial role in
            preventing accidents and protecting workers. This page provides an
            overview of essential safety supplies, their applications, and how
            they are commonly used on construction sites.
          </p>

          {/* <!-- Types of Safety Supplies Section --> */}
          <section className="mb-0">
            <h2 className="mb-10 text-2xl font-semibold">
              Types of Safety Supplies
            </h2>
            <p className="mb-4">
              Concrete contractors rely on a range of safety supplies to protect
              against physical hazards, enhance visibility, and maintain a
              controlled work environment. The following are some of the most
              essential safety supplies:
            </p>
            <ul className="list-inside list-disc space-y-2">
              <li>
                <span className="font-semibold">Gloves</span> – Protect hands
                from cuts, abrasions, and chemical exposure.
              </li>
              <li>
                <span className="font-semibold">Vests</span> – Increase
                visibility of workers on site.
              </li>
              <li>
                <span className="font-semibold">Flags and Wire Flags</span> –
                Mark areas or communicate with equipment operators.
              </li>
              <li>
                <span className="font-semibold">
                  Goggles and Safety Glasses
                </span>{' '}
                – Shield eyes from debris and dust.
              </li>
              <li>
                <span className="font-semibold">Traffic Cones</span> – Create
                barriers and redirect traffic.
              </li>
              <li>
                <span className="font-semibold">Dust Masks</span> – Prevent
                inhalation of dust and other particles.
              </li>
              <li>
                <span className="font-semibold">First Aid Kits</span> – Provide
                immediate response to minor injuries.
              </li>
              <li>
                <span className="font-semibold">
                  Orange & Green Barricade and Safety Fencing
                </span>{' '}
                – Mark off hazardous areas.
              </li>
              <li>
                <span className="font-semibold">Caution Tape</span> – Indicate
                restricted areas.
              </li>
              <li>
                <span className="font-semibold">Ear Plugs</span> – Reduce
                exposure to loud construction noises.
              </li>
              <li>
                <span className="font-semibold">Flagging and Faceshields</span>{' '}
                – Protect workers’ faces and guide machinery operators.
              </li>
              <li>
                <span className="font-semibold">Hard Hats</span> – Protect
                against head injuries.
              </li>
              <li>
                <span className="font-semibold">Welders Goggles</span> – Shield
                eyes from welding sparks.
              </li>
              <li>
                <span className="font-semibold">Safety Belts</span> – Secure
                workers when working at height.
              </li>
            </ul>
          </section>

          {/* <!-- Detailed Descriptions of Each Supply Type --> */}
          <section className="mb-0">
            <h2 className="mb-10 text-2xl font-semibold">
              Detailed Safety Supply Descriptions and Use Cases
            </h2>

            <div className="grid grid-cols-1 gap-6">
              {/* <!-- Gloves --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Gloves</h3>
                <p className="mb-2">
                  Gloves protect hands from cuts, abrasions, and chemical
                  exposure. Made from durable materials such as leather,
                  nitrile, or rubber.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Handling rough or
                  sharp materials, pouring concrete, and dealing with chemicals.
                </p>
              </div>

              {/* <!-- Vests --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Vests</h3>
                <p className="mb-2">
                  Hi-vis vests ensure workers are easily seen, especially in
                  areas with heavy machinery or low light.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Enhancing
                  visibility for workers on active job sites.
                </p>
              </div>

              {/* <!-- Flags and Wire Flags --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">
                  Flags and Wire Flags
                </h3>
                <p className="mb-2">
                  Flags are used to signal directions or mark specific areas on
                  a construction site.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Communicating
                  with equipment operators or designating hazardous zones.
                </p>
              </div>

              {/* <!-- Goggles and Safety Glasses --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">
                  Goggles and Safety Glasses
                </h3>
                <p className="mb-2">
                  Eye protection that prevents debris, dust, or chemicals from
                  entering the eyes.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Cutting,
                  drilling, or grinding concrete, where particles can pose a
                  hazard.
                </p>
              </div>

              {/* <!-- Traffic Cones --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Traffic Cones</h3>
                <p className="mb-2">
                  Traffic cones mark areas or direct vehicles around work zones,
                  providing a clear visual boundary.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Setting up safe
                  work zones around construction sites.
                </p>
              </div>

              {/* <!-- Dust Masks --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Dust Masks</h3>
                <p className="mb-2">
                  Dust masks prevent inhalation of harmful particles, especially
                  when working with materials that generate dust.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Cutting or
                  breaking concrete, preventing dust inhalation.
                </p>
              </div>

              {/* <!-- First Aid Kits --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">First Aid Kits</h3>
                <p className="mb-2">
                  Kits that contain essential medical supplies for treating
                  minor injuries on site.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Immediate
                  treatment of cuts, scrapes, and other minor injuries.
                </p>
              </div>

              {/* <!-- Orange & Green Barricade and Safety Fencing --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">
                  Orange & Green Barricade and Safety Fencing
                </h3>
                <p className="mb-2">
                  Safety barricades and fencing mark off restricted or dangerous
                  areas, preventing unauthorized access.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Controlling
                  pedestrian access to work areas.
                </p>
              </div>

              {/* <!-- Caution Tape --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Caution Tape</h3>
                <p className="mb-2">
                  Used to mark hazardous areas and alert people to potential
                  danger.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Marking off
                  restricted or dangerous areas on site.
                </p>
              </div>

              {/* <!-- Ear Plugs --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Ear Plugs</h3>
                <p className="mb-2">
                  Hearing protection to reduce exposure to loud construction
                  sounds, preventing hearing damage.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>When operating
                  loud machinery or equipment.
                </p>
              </div>

              {/* <!-- Flagging and Faceshields --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">
                  Flagging and Faceshields
                </h3>
                <p className="mb-2">
                  Faceshields protect the face from debris, while flagging is
                  used to communicate directions to equipment operators.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Grinding,
                  welding, and flagging areas for machinery navigation.
                </p>
              </div>

              {/* <!-- Hard Hats --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Hard Hats</h3>
                <p className="mb-2">
                  Protective headgear that prevents head injuries from falling
                  objects and collisions.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Required on most
                  job sites to protect against head injuries.
                </p>
              </div>

              {/* <!-- Welders Goggles --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Welders Goggles</h3>
                <p className="mb-2">
                  Specialized goggles designed to protect eyes from the intense
                  light and sparks generated during welding.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Protecting eyes
                  when performing welding tasks on steel reinforcements or
                  structural elements.
                </p>
              </div>

              {/* <!-- Safety Belts --> */}
              <div className="mb-6 grid gap-y-2">
                <h3 className="mb-2 text-xl font-semibold">Safety Belts</h3>
                <p className="mb-2">
                  Harnesses and safety belts prevent falls, especially when
                  working at height.
                </p>
                <p>
                  <span className="font-bold">Use Case: </span>Securing workers
                  who are operating at elevated heights, ensuring fall
                  protection.
                </p>
              </div>
            </div>
          </section>

          {/* <!-- How Concrete Contractors Use Safety Supplies Section --> */}
          <section className="mb-0">
            <h2 className="mb-2 text-2xl font-semibold">
              How Concrete Contractors Use Safety Supplies on Projects
            </h2>
            <p className="mb-4">
              Concrete contractors use a wide range of safety supplies to create
              a secure job site and protect their crew. Here’s how these
              essential items are used across various construction tasks:
            </p>

            <ul className="list-inside list-disc space-y-2">
              <li>
                <span className="font-semibold">Personal Protection:</span>{' '}
                Gloves, goggles, hard hats, and dust masks are worn by workers
                to minimize exposure to hazards like dust, sharp edges, and
                heavy materials.
              </li>
              <li>
                <span className="font-semibold">Site Visibility:</span> Vests,
                flags, and caution tape are used to improve visibility,
                especially in areas with heavy machinery and to mark off
                restricted zones.
              </li>
              <li>
                <span className="font-semibold">Traffic Control:</span> Traffic
                cones, barricades, and safety fencing help direct pedestrian and
                vehicle traffic, keeping the workspace organized and reducing
                accident risks.
              </li>
              <li>
                <span className="font-semibold">Noise Reduction:</span> Ear
                plugs are crucial when contractors operate loud equipment,
                protecting workers from long-term hearing damage.
              </li>
              <li>
                <span className="font-semibold">Fall Prevention:</span> Safety
                belts and harnesses are essential when contractors work at
                height, securing workers and preventing falls.
              </li>
              <li>
                <span className="font-semibold">Emergency Response:</span> First
                aid kits provide immediate treatment options for minor injuries,
                keeping contractors safe and prepared on site.
              </li>
            </ul>
          </section>

          {/* <!-- Conclusion Section --> */}
          <section className="mb-0">
            <h2 className="mb-2 text-2xl font-semibold">Conclusion</h2>
            <p>
              Equipped with the right safety supplies, concrete contractors can
              ensure both compliance with safety standards and the well-being of
              their workforce. From protective gear to traffic control items,
              each safety item serves a purpose in creating a secure and
              efficient job site. By understanding the applications of these
              tools, contractors can work confidently and safely on a range of
              construction projects.
            </p>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default SafetySupplies
