// Modules
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { Upsell } from '../components/upsells/bagged'

// Assets
import bagged from '../images/assets/bagged-concrete-mix.png'

const BaggedConcrete = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['bagged'])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="About Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article"
      />
      <Header />
      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          <div className="flex flex-col gap-y-8">
            <h1 className="text-3xl font-bold tracking-wide">
              Bagged Concrete for Concrete Contractors
            </h1>
            <div className="flex flex-col rounded-lg bg-white shadow-md">
              <img
                className="h-96 w-auto rounded-lg object-cover"
                src={bagged}
                alt="bagged concrete"
              />
            </div>
            <p>
              Bagged concrete is an essential material in construction,
              providing flexibility, ease of transport, and a convenient way to
              handle small to medium-scale concrete projects. Bagged concrete
              comes in different types, each suited for specific applications
              and environments. This page provides an overview of common types
              of bagged concrete, their use cases, and how contractors can
              effectively use them on various projects.
            </p>
          </div>
          <div className="flex flex-col gap-y-8">
            <section className="mb-0">
              <h2 className="mb-8 text-2xl font-semibold text-gray-800">
                Types of Bagged Concrete
              </h2>
              <p className="mb-4">
                Bagged concrete comes in various formulations to meet specific
                needs, from high-strength requirements to quick-setting
                applications. Common types include:
              </p>
              <ul className="list-inside list-disc space-y-4">
                <li>
                  <span className="font-semibold">Standard Concrete Mix</span> –
                  A general-purpose mix for most concrete applications.
                </li>
                <li>
                  <span className="font-semibold">High-Strength Concrete</span>{' '}
                  – Designed for projects requiring greater load-bearing
                  capacity.
                </li>
                <li>
                  <span className="font-semibold">Fast-Setting Concrete</span> –
                  Ideal for projects that require quick setup and curing times.
                </li>
                <li>
                  <span className="font-semibold">
                    Fiber-Reinforced Concrete
                  </span>{' '}
                  – Contains fibers for added durability and crack resistance.
                </li>
                <li>
                  <span className="font-semibold">Mortar Mix</span> – A
                  versatile mix for bonding bricks, blocks, and stone.
                </li>
                <li>
                  <span className="font-semibold">Non-Shrink Grout</span> – Used
                  for filling and stabilizing areas without shrinking or
                  cracking.
                </li>
              </ul>
            </section>
          </div>
          <div className="flex flex-col gap-y-8">
            <section className="mb-0">
              <h2 className="mb-8 text-2xl font-semibold text-gray-800">
                Detailed Bagged Concrete Descriptions and Use Cases
              </h2>

              <div className="grid grid-cols-1 gap-6">
                {/* <!-- Standard Concrete Mix --> */}
                <div className="mb-2">
                  <h3 className="mb-2 text-xl font-semibold">
                    Standard Concrete Mix
                  </h3>
                  <p className="mb-2">
                    A versatile concrete mix suited for a wide range of general
                    applications. It provides durability and stability, suitable
                    for most basic concrete needs.
                  </p>
                  <p>
                    <span className="font-bold">Use Case:</span> Footings,
                    slabs, and repairs for walkways and patios.
                  </p>
                </div>

                {/* <!-- High-Strength Concrete --> */}
                <div className="mb-2">
                  <h3 className="mb-2 text-xl font-semibold">
                    High-Strength Concrete
                  </h3>
                  <p className="mb-2">
                    A specially formulated mix for projects needing high
                    load-bearing capacity. This concrete offers greater
                    compressive strength than standard mixes.
                  </p>
                  <p>
                    <span className="font-bold">Use Case:</span> Structural
                    supports, columns, and load-bearing walls.
                  </p>
                </div>

                {/* <!-- Fast-Setting Concrete --> */}
                <div className="mb-2">
                  <h3 className="mb-2 text-xl font-semibold">
                    Fast-Setting Concrete
                  </h3>
                  <p className="mb-2">
                    This mix sets quickly, allowing for rapid project
                    completion. Ideal for projects requiring immediate usability
                    and fast drying.
                  </p>
                  <p>
                    <span className="font-bold">Use Case:</span> Setting fence
                    posts, poles, and other structures that need to be set
                    quickly.
                  </p>
                </div>

                {/* <!-- Fiber-Reinforced Concrete --> */}
                <div className="mb-2">
                  <h3 className="mb-2 text-xl font-semibold">
                    Fiber-Reinforced Concrete
                  </h3>
                  <p className="mb-2">
                    Contains added fibers that improve tensile strength and
                    crack resistance, making it a durable choice for exposed
                    surfaces.
                  </p>
                  <p>
                    <span className="font-bold">Use Case:</span> Driveways,
                    sidewalks, and patios where surface durability is important.
                  </p>
                </div>

                {/* <!-- Mortar Mix --> */}
                <div className="mb-2">
                  <h3 className="mb-2 text-xl font-semibold">Mortar Mix</h3>
                  <p className="mb-2">
                    A binding mix used primarily for laying bricks, stones, and
                    blocks. Mortar mix provides strong adhesion and smooth
                    application.
                  </p>
                  <p>
                    <span className="font-bold">Use Case:</span> Bricklaying,
                    block walls, and stone veneer installations.
                  </p>
                </div>

                {/* <!-- Non-Shrink Grout --> */}
                <div className="mb-2">
                  <h3 className="mb-2 text-xl font-semibold">
                    Non-Shrink Grout
                  </h3>
                  <p className="mb-2">
                    A specialized grout that remains stable without shrinking or
                    cracking, providing strong support for filling and anchoring
                    applications.
                  </p>
                  <p>
                    <span className="font-bold">Use Case:</span> Grouting
                    machinery bases, anchoring bolts, and filling voids in
                    concrete.
                  </p>
                </div>
              </div>
            </section>
          </div>

          <div className="flex flex-col gap-y-8">
            <section className="">
              <h2 className="mb-6 text-2xl font-semibold text-gray-800">
                How Concrete Contractors Use Bagged Concrete on Projects
              </h2>
              <p className="mb-6">
                Bagged concrete is popular for both large and small projects due
                to its convenience and adaptability. Concrete contractors
                commonly use bagged concrete in the following ways:
              </p>

              <ul className="list-inside list-disc space-y-4">
                <li>
                  <span className="font-semibold">
                    Small to Medium-Sized Projects
                  </span>
                  : Bagged concrete is ideal for smaller projects where the use
                  of a concrete mixer isn’t practical, such as repairs or small
                  slab installations.
                </li>
                <li>
                  <span className="font-semibold">
                    Remote or Hard-to-Reach Locations
                  </span>
                  : Since bagged concrete is easy to transport, it’s useful in
                  locations where delivery of ready-mix concrete is not
                  feasible.
                </li>
                <li>
                  <span className="font-semibold">Quick Repairs</span>:
                  Fast-setting and fiber-reinforced bagged concrete make
                  on-the-spot repairs quick and durable, ensuring minimal
                  disruption.
                </li>
                <li>
                  <span className="font-semibold">
                    Foundation and Support Applications
                  </span>
                  : High-strength concrete and non-shrink grout provide strong
                  foundations for smaller structural projects.
                </li>
                <li>
                  <span className="font-semibold">
                    Setting Posts and Fencing
                  </span>
                  : Contractors often use fast-setting bagged concrete for
                  projects requiring a quick installation, such as setting fence
                  posts and sign poles.
                </li>
              </ul>
            </section>
          </div>

          <section className="mb-8">
            {/* <h2 className="mb-6 text-2xl font-semibold text-gray-800">
                Conclusion
              </h2> */}
            <p>
              Bagged concrete offers concrete contractors a versatile,
              convenient, and reliable solution for a variety of construction
              needs. From general-purpose mixes to high-strength and
              fast-setting options, bagged concrete provides flexibility and
              strength in a format that’s easy to transport and use. With its
              broad applications, bagged concrete continues to be an essential
              material on job sites for both new construction and repair work.
            </p>
          </section>
        </main>
      </div>
      <Upsell />
      <Footer />
    </div>
  )
}

export default BaggedConcrete
