// @ts-nocheck

// Components
import SEO from '../components/seo'
import { Hero } from '../components/hero'
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { Features } from '../components/features'
import { Upsell } from '../components/upsells/material'

const Home = (): JSX.Element => {
  return (
    <div className="bg-gray-brand">
      <SEO
        title="Welcome to Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article"
      />
      <Header />
      <Hero />
      <Features />
      <Upsell />
      <Footer />
    </div>
  )
}

export default Home
