import { InboxIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Ready Mix Concrete',
    description:
      "We're breaking new ground in concrete construction supply! Proudly the first in the industry to offer both high-quality building materials and ready-mix concrete under one roof, we're here to save you time, reduce hassle, and streamline your sourcing process.",
    href: '#',
    icon: UsersIcon,
  },
  {
    name: 'Free Delivery',
    description:
      "We know that every dollar counts on your project. That's why we're offering Free Delivery on all orders over $1000, anywhere in the Greater Houston Area. Get the materials you need, right to your site, without the extra costs!",
    href: '#',
    icon: InboxIcon,
  },
  {
    name: 'Guaranteed Best Pricing',
    description:
      "We're committed to offering the most competitive prices on the market for all your building material needs. With our Price Match Guarantee, you can shop confidently, knowing that if you find a lower price on the same product from any local competitor, we’ll match it",
    href: '#',
    icon: TrashIcon,
  },
]

export function Features() {
  return (
    <div className="bg-gray-brand py-24 sm:py-32 lg:py-24">
      <div className="mx-auto max-w-7xl px-8 lg:px-8">
        <div className="mx-auto max-w-4xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            We are your trusted materials partner empowering your businesses
            success.
          </h2>
          <p className="mt-6 text-xl leading-8 text-gray-600">
            We are focused on giving the small to medium-sized concrete
            contractor the materials and tools they need to build their
            business.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-lg font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-brand">
                    <feature.icon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  {/* <p className="mt-6">
                    <a
                      href={feature.href}
                      className="text-base font-semibold leading-6 text-blue-brand"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

// // Modules
// import clsx from 'clsx'
// import { Tab } from '@headlessui/react'
// import { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'

// // Components
// import { Container } from './container'

// export function Features() {
//   const { t } = useTranslation(['features'])

//   let [tabOrientation, setTabOrientation] = useState<'horizontal' | 'vertical'>(
//     'horizontal',
//   )

//   useEffect(() => {
//     let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

//     function onMediaQueryChange({ matches }: { matches: boolean }) {
//       setTabOrientation(matches ? 'vertical' : 'horizontal')
//     }

//     onMediaQueryChange(lgMediaQuery)
//     lgMediaQuery.addEventListener('change', onMediaQueryChange)

//     return () => {
//       lgMediaQuery.removeEventListener('change', onMediaQueryChange)
//     }
//   }, [])

//   return (
//     <section
//       id="features"
//       aria-label="Features for running your books"
//       className="relative overflow-hidden bg-gradient-to-b from-blue-600 from-75% to-teal-500 to-100% pb-28 pt-12 sm:py-24"
//     >
//       <Container className="relative">
//         <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-5xl">
//           <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
//             {t('header')}
//           </h2>
//           <p className="mt-6 text-lg tracking-tight text-blue-100">
//             {t('subheader')}
//           </p>
//         </div>
//         <Tab.Group
//           as="div"
//           className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
//           vertical={tabOrientation === 'vertical'}
//         >
//           {({ selectedIndex }) => (
//             <>
//               <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
//                 <Tab.List className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
//                   {features.map((feature, featureIndex) => (
//                     <div
//                       key={featureIndex}
//                       className={clsx(
//                         'group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6',
//                         selectedIndex === featureIndex
//                           ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
//                           : 'hover:bg-white/10 lg:hover:bg-white/5',
//                       )}
//                     >
//                       <h3>
//                         <Tab
//                           className={clsx(
//                             'font-display text-lg ui-not-focus-visible:outline-none',
//                             selectedIndex === featureIndex
//                               ? 'text-blue-600 lg:text-white'
//                               : 'text-blue-100 hover:text-white lg:text-white',
//                           )}
//                         >
//                           <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
//                           {t(`${feature.title}`)}
//                         </Tab>
//                       </h3>
//                       <p
//                         className={clsx(
//                           'mt-2 hidden text-sm lg:block',
//                           selectedIndex === featureIndex
//                             ? 'text-white'
//                             : 'text-blue-100 group-hover:text-white',
//                         )}
//                       >
//                         {t(`${feature.description}`)}
//                       </p>
//                     </div>
//                   ))}
//                 </Tab.List>
//               </div>
//               <Tab.Panels className="lg:col-span-7">
//                 {features.map((feature) => (
//                   <Tab.Panel key={feature.title} unmount={false}>
//                     <div className="relative sm:px-6 lg:hidden">
//                       <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
//                       <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
//                         {feature.description}
//                       </p>
//                     </div>
//                     <div className="h-128 mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
//                       <img
//                         className="w-full"
//                         src={feature.image}
//                         alt=""
//                         sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
//                       />
//                     </div>
//                   </Tab.Panel>
//                 ))}
//               </Tab.Panels>
//             </>
//           )}
//         </Tab.Group>
//       </Container>
//     </section>
//   )
// }
