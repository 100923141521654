// Modules
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import headshotChristian from '../images/headshots/christian.jpg'
import headshotAbraham from '../images/headshots/abraham.jpg'
import headshotPatty from '../images/headshots/patty.jpg'

const Team = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['team'])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Meet the team"
        description="Solocrete Supply Team"
        name="Solocrete Supply Team"
        type="article"
      />
      <Header />
      <div className="mx-auto mb-16 w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          <h1 className="text-3xl font-bold tracking-wide">
            {t('founding-team-header')}
          </h1>
          <div className="flex flex-col gap-x-8 lg:flex-row">
            <img
              className="lg:christian h-auto w-auto rounded-lg"
              src={headshotChristian}
              alt="Solocrete"
            />
            <div className="flex flex-col gap-y-6">
              <h2 className="mt-4 text-xl font-medium lg:-mt-1">
                {t('founding-team-ceo')}
              </h2>
              <p>{t('founding-team-ceo-bio-1')}</p>
              <p>{t('founding-team-ceo-bio-2')}</p>
            </div>
          </div>

          <div className="flex flex-col flex-col-reverse gap-x-8 lg:flex-row">
            <div className="flex flex-col gap-y-6">
              <h2 className="mt-4 text-xl font-medium lg:-mt-1">
                {t('founding-team-so')}
              </h2>
              <p>{t('founding-team-so-bio-1')}</p>
              <p>{t('founding-team-so-bio-2')}</p>
            </div>
            <img
              className="lg:christian h-auto w-auto rounded-lg"
              src={headshotAbraham}
              alt="Abraham Garcia"
            />
          </div>
          <div className="flex flex-col gap-x-8 lg:flex-row">
            <img
              className="lg:christian h-auto w-auto rounded-lg"
              src={headshotPatty}
              alt="Patty Moreno"
            />
            <div className="flex flex-col gap-y-6">
              <h2 className="mt-4 text-xl font-medium lg:-mt-1">
                {t('founding-team-ga')}
              </h2>
              <p>{t('founding-team-ga-bio-1')}</p>
              <p>{t('founding-team-ga-bio-2')}</p>
            </div>
          </div>

          <div className="flex flex-col gap-x-8 lg:flex-row">
            <div className="flex flex-col gap-y-6">
              <h2 className="mt-4 text-xl font-medium lg:-mt-1">
                {t('founding-team-dev')}
              </h2>
              <p>{t('founding-team-dev-bio-1')}</p>
              <p>{t('founding-team-dev-bio-2')}</p>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default Team
