// Modules
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import rvsLogo from '../images/logos/rvs.png'
import cemexLogo from '../images/logos/cemexventures.webp'

const AboutUs = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['aboutus'])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="About Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article"
      />
      <Header />
      <div className="mx-auto mb-16 w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          <div className="flex flex-col gap-y-8">
            <h1 className="text-3xl font-bold tracking-wide">
              {t('about-solocrete-header')}
            </h1>
            <p>{t('about-solocrete-text')}</p>
          </div>
          <div className="flex flex-col gap-y-8">
            <h3 className="text-xl font-medium">{t('about-cemex-header')}</h3>
            <div className="flex flex-col gap-x-8 md:flex-row">
              <div className="mb-6 flex h-4/5 w-4/5 flex-col md:mb-0  md:h-auto md:w-auto">
                <img
                  src={cemexLogo}
                  // className="h-2/3 w-2/3 rounded-lg md:h-full md:w-full"
                  alt="Cemex"
                  width="850"
                  height="140"
                />
              </div>
              <div className="flex flex-col gap-y-8">
                <p>
                  {t('about-cemex-text')}{' '}
                  <a
                    href="https://www.cemexventures.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.cemexventures.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-8">
            <h3 className="text-xl font-medium">{t('about-rvs-header')}</h3>
            <div className="flex flex-col gap-x-8 md:flex-row">
              <div className="mb-6 flex h-1/2 flex-col md:mb-0 md:h-full md:w-3/6">
                <img
                  className="h-2/3 w-2/3 rounded-lg md:h-auto md:w-auto"
                  src={rvsLogo}
                  alt="Rainmaking Venture Studio"
                />
              </div>
              <div className="flex flex-col gap-y-8">
                <p>{t('about-rvs-text-1')}</p>
                <p>{t('about-rvs-text-2')}</p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs

// // Modules
// import { useTranslation } from 'react-i18next'

// // Components
// import SEO from '../components/seo'
// import { Header } from '../components/header'
// import { Footer } from '../components/footer'

// // Assets
// import headshotJohn from '../images/headshots/john.png'
// import headshotChristian from '../images/headshots/christian.jpg'

// const AboutUs = (): JSX.Element => {
//   // Translation Hook
//   const { t } = useTranslation(['aboutus'])

//   return (
//     <div className="min-h-screen">
//       <SEO
//         title="About Solocrete Supply"
//         description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
//         name="Solocrete Supply"
//         type="article"
//       />
//       <Header />
//       <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
//         <main className="my-12 flex flex-col gap-y-8">
//           <div className="flex flex-col gap-y-4">
//             <h1 className="text-3xl font-bold tracking-wide">
//               {t('about-solocrete-header')}
//             </h1>
//             <p>{t('about-solocrete-text')}</p>
//           </div>
//           <div className="flex flex-col gap-y-4">
//             <h3 className="text-xl font-medium">{t('about-cemex-header')}</h3>
//             <p>
//               {t('about-cemex-text')}{' '}
//               <a
//                 href="https://www.cemexventures.com"
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 www.cemexventures.com
//               </a>
//               .
//             </p>
//           </div>
//           <div className="flex flex-col gap-y-4">
//             <h3 className="text-xl font-medium">{t('about-rvs-header')}</h3>
//             <p>{t('about-rvs-text-1')}</p>
//             <p>{t('about-rvs-text-2')}</p>
//           </div>
//           <h2 className="text-3xl font-bold tracking-wide">
//             {t('founding-team-header')}
//           </h2>
//           <div className="flex flex-col gap-x-4 lg:flex-row">
//             <img
//               className="h-auto w-auto lg:h-52"
//               src={headshotJohn}
//               alt="Solocrete"
//             />
//             <div className="flex flex-col gap-y-2">
//               <h2 className="mt-4 text-xl font-medium lg:-mt-1.5">
//                 {t('founding-team-ceo')}
//               </h2>
//               <p>{t('founding-team-ceo-bio-1')}</p>
//               <p>{t('founding-team-ceo-bio-2')}</p>
//               <p>{t('founding-team-ceo-bio-3')}</p>
//             </div>
//           </div>
//           <div className="flex flex-col gap-x-4 lg:flex-row">
//             <img
//               className="lg:christian h-auto w-auto"
//               src={headshotChristian}
//               alt="Solocrete"
//             />
//             <div className="flex flex-col gap-y-2">
//               <h2 className="mt-4 text-xl font-medium lg:-mt-1">
//                 {t('founding-team-cto')}
//               </h2>
//               <p>{t('founding-team-cto-bio-1')}</p>
//               <p>{t('founding-team-cto-bio-2')}</p>
//             </div>
//           </div>
//         </main>
//       </div>
//       <Footer />
//     </div>
//   )
// }

// export default AboutUs
