// Modules
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Button } from '../components/button'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import lumber from '../images/assets/lumber.jpg'

const LumberPlywood = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['lumberplywood'])

  const videoRef = useRef<HTMLVideoElement>(null)

  const attemptPlay = () => {
    if (videoRef) {
      if (videoRef.current !== null) {
        videoRef.current.play().catch((error: any) => {
          console.error('Error attempting to play', error)
        })
      }
    }
  }

  useEffect(() => {
    attemptPlay()
  }, [])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Solocrete Supply: Lumber and Plywood"
        description="Solocrete Supply is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply: Lumber and Plywood"
        type="article"
      />
      <Header />

      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          <div className="flex flex-col gap-y-8">
            <h1 className="text-3xl font-bold tracking-wide">
              Lumber and Plywood for Concrete Contractors
            </h1>
            {/* <figure role="figure">
              <div className="flex items-center justify-center overflow-hidden rounded-lg bg-gray-900 bg-opacity-100 drop-shadow-lg">
                <div style={{ maxWidth: '1080px' }} className="">
                  <video
                    data-testid="video-asset"
                    // src="https://media.istockphoto.com/id/1740927518/video/carpenter-inspects-the-quality-of-transformed-wood-products-to-be-complete-for-export-and.mp4?s=mp4-640x640-is&amp;k=20&amp;c=KApqOAgYfOoDKa10Eve7AScoxGMn-AqnIgBop-1wYBU="
                    // alt="Carpenter inspects the quality of transformed wood products to be complete for export and processed into other furniture Timber Stock Footage Video"
                    controls={true}
                    controlsList="nodownload"
                    autoPlay={true}
                    loop={true}
                    ref={videoRef}
                  >
                    <source
                      src="https://media.istockphoto.com/id/1740927518/video/carpenter-inspects-the-quality-of-transformed-wood-products-to-be-complete-for-export-and.mp4?s=mp4-640x640-is&amp;k=20&amp;c=KApqOAgYfOoDKa10Eve7AScoxGMn-AqnIgBop-1wYBU="
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </figure> */}
            <img
              className="w-auto rounded-lg object-cover sm:h-96 md:h-96"
              src={lumber}
              alt="Lumber and Plywood"
            />
          </div>
          <div className="flex flex-col gap-y-8">
            <p className="text-base">
              Concrete contractors use a variety of lumber and plywood types for
              forming, bracing, and framework construction. The choice of
              material directly impacts the stability of forms, the quality of
              concrete surfaces, and overall project efficiency. Below, we
              provide an overview of commonly used lumber and plywood types,
              grades, and their applications, as well as a guide on how to use
              them for concrete forming.
            </p>
          </div>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">Types of Lumber</h2>
            <p className="text-base">
              There are several types of lumber available for construction, with
              different dimensions and characteristics suited to concrete work:
            </p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                <span className="font-bold">2x4</span>: Commonly used for
                bracing and support during concrete forming.
              </li>
              <li>
                <span className="font-bold">2x6</span>: Provides more strength
                and rigidity, useful for larger forms or higher walls.
              </li>
              <li>
                <span className="font-bold">2x8</span>: Ideal for heavy-duty
                forming, often used in beams and structural elements.
              </li>
              <li>
                <span className="font-bold">4x4</span>: Typically used for posts
                and stakes to hold formwork in place.
              </li>
              <li>
                <span className="font-bold">Lengths</span>: Lumber is available
                in standard lengths of 8, 10, 12, 14, 16, and 20 feet, allowing
                for customization based on project needs.
              </li>
            </ul>

            <h2 className="text-xl font-bold tracking-wide">Lumber Grades</h2>
            <p className="text-base">
              Lumber is categorized into grades based on its strength,
              appearance, and durability. For concrete forming, contractors
              generally use the following grades:
            </p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                <span className="font-bold">#1 Grade</span>: Highest quality,
                with minimal knots and defects. Used when structural integrity
                is critical, such as in large or complex forms.
              </li>
              <li>
                <span className="font-bold">#2 Grade</span>: Moderate strength,
                contains more knots and imperfections but still suitable for
                most concrete forms. This is a common choice for everyday
                construction projects.
              </li>
              <li>
                <span className="font-bold">#3 Grade</span>: Lower quality, with
                larger knots and defects. Used for temporary or non-load-bearing
                applications, such as bracing or secondary support.
              </li>
              <li>
                <span className="font-bold">Construction Grade</span>: Often a
                mix of grades #2 and #3, it’s suitable for most concrete
                formwork where aesthetics aren’t a concern.
              </li>
            </ul>
            <p className="text-base">
              <span className="font-bold">Use Case Example:</span> For building
              a sturdy wall form, #2 Grade 2x4 or 2x6 lumber is ideal because it
              balances cost and strength.
            </p>

            <h2 className="text-xl font-bold tracking-wide">
              Types of Plywood
            </h2>
            <p className="text-base">
              Plywood is often used in concrete forming for sheathing and smooth
              finishing. There are various types and thicknesses of plywood, but
              for concrete work, the most common options include:
            </p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                <span className="font-bold">3/4" Exterior Plywood</span>: Thick
                and durable, ideal for concrete forming because it can withstand
                the pressure of wet concrete.
              </li>
              <li>
                <span className="font-bold">1/2" Exterior Plywood</span>:
                Lighter and used for smaller forms or when minimal load is
                expected.
              </li>
              <li>
                <span className="font-bold">1-1/8" Plywood</span>: Extra thick
                plywood for heavy-duty applications, typically used in
                large-scale concrete pours.
              </li>
            </ul>

            <h2 className="text-xl font-bold tracking-wide">Plywood Grades</h2>
            <p className="text-base">
              Plywood is also graded based on the quality of its face and back
              veneers. Concrete contractors typically work with the following
              grades:
            </p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                <span className="font-bold">A-C Exterior</span>: One smooth face
                (Grade A), ideal for forms that require a smooth concrete
                surface, and one rough face (Grade C).
              </li>
              <li>
                <span className="font-bold">B-B Exterior</span>: Both sides have
                moderate imperfections, used where both sides of the form may be
                exposed but don’t require a perfect finish.
              </li>
              <li>
                <span className="font-bold">C-D (CDX) Plywood</span>: A rougher
                and more economical option, CDX is often used for temporary or
                low-exposure forms where a polished finish isn’t required.
              </li>
            </ul>
            <p className="text-base">
              <span className="font-bold">Use Case Example:</span> For a project
              requiring smooth concrete surfaces, A-C Exterior plywood is
              preferred, as it ensures minimal blemishes on the finished
              concrete.
            </p>

            <h2 className="text-xl font-bold tracking-wide">
              Using Lumber and Plywood for Concrete Forming
            </h2>
            <p className="text-base">
              The use of wood for concrete forming requires attention to detail
              and proper planning. Below are steps and considerations when using
              lumber and plywood for formwork:
            </p>
            <h3 className="text-lg font-bold tracking-wide">
              1. Preparing the Lumber
            </h3>
            <p className="text-base">
              Choose the appropriate grade of lumber depending on the load
              requirements and quality of the concrete finish desired. Ensure
              the lumber is straight and free from major defects such as warping
              or twisting. Cut lumber to the appropriate lengths for framing,
              posts, or braces.
            </p>

            <h3 className="text-lg font-bold tracking-wide">
              2. Building Formwork with Lumber
            </h3>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                Build formwork using 2x4 or 2x6 lumber for walls and 4x4 posts
                for support.
              </li>
              <li>
                Place horizontal and vertical studs at regular intervals
                (typically every 12" to 16").
              </li>
              <li>
                Ensure that all joints are properly reinforced with nails or
                screws to prevent any movement during the pour.
              </li>
              <li>
                Use stakes to secure forms in place to maintain rigidity during
                the concrete pour.
              </li>
            </ul>

            <h3 className="text-lg font-bold tracking-wide">
              3. Sheathing with Plywood
            </h3>
            <p className="text-base">
              Plywood panels are attached to the lumber frame to create a smooth
              surface for the concrete. Consider the following when attaching
              plywood:
            </p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                Use 3/4" or thicker exterior-grade plywood for larger forms,
                ensuring the plywood can handle the pressure from the wet
                concrete.
              </li>
              <li>
                Secure plywood sheathing tightly to the lumber frame using
                screws or nails, making sure that the surface is flat and free
                from gaps.
              </li>
              <li>
                If using thinner plywood, reinforce it with additional lumber
                supports to prevent bowing or warping.
              </li>
              <li>
                To ensure a clean release after the pour, apply form release oil
                to the plywood before pouring the concrete.
              </li>
            </ul>

            <h3 className="text-lg font-bold tracking-wide">
              4. Reinforcing the Forms
            </h3>
            <p className="text-base">
              For larger concrete pours or walls, additional bracing may be
              required to prevent bulging. Use diagonal braces or extra 2x4
              supports along the back of the form to enhance strength.
            </p>

            <h3 className="text-lg font-bold tracking-wide">
              5. Concrete Pouring and Removal
            </h3>
            <p className="text-base">
              Once the forms are built, double-check for stability and
              levelness. After pouring the concrete, let it cure fully before
              removing the formwork. Proper care will ensure that the forms can
              be reused multiple times.
            </p>

            <p className="text-base">
              By selecting the correct lumber and plywood, and following best
              practices for formwork, you can ensure a successful concrete pour
              with clean finishes and efficient use of materials.
            </p>
          </div>
        </main>
      </div>

      <Footer />
    </div>
  )
}

export default LumberPlywood
