'use client'

import { useState } from 'react'
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from '@heroicons/react/20/solid'
import LanguageSelector from './languageselector'
import logoSolocrete from '../images/logos/solocrete-supply.svg'

const products = [
  {
    name: 'Rebar & Accessories',
    description: '',
    href: '/rebar-and-accessories',
    icon: ChartPieIcon,
  },
  {
    name: 'Lumber & Plywood',
    description: '',
    href: '/lumber-and-plywood',
    icon: CursorArrowRaysIcon,
  },
  {
    name: 'Fasteners',
    description: '',
    href: '/fasteners',
    icon: FingerPrintIcon,
  },
  {
    name: 'Safety Supplies',
    description: '',
    href: '/safety-supplies',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Waterproofing',
    description: '',
    href: '/waterproofing',
    icon: ArrowPathIcon,
  },
]

const materialCallsToAction = [
  {
    name: 'Request a quote',
    href: '/building-materials-quote',
    icon: PlayCircleIcon,
  },
  { name: 'Contact sales', href: 'tel:346-396-0016', icon: PhoneIcon },
]

const concrete = [
  {
    name: 'Ready Mix Concrete',
    description: '',
    href: '/ready-mix-concrete',
    icon: ChartPieIcon,
  },
  {
    name: 'Bagged Concrete',
    description: '',
    href: '/bagged-concrete',
    icon: CursorArrowRaysIcon,
  },
]

const concreteCallsToAction = [
  {
    name: 'Request a quote',
    href: '/ready-mix-quote',
    icon: PlayCircleIcon,
  },
  { name: 'Contact sales', href: 'tel:346-396-0016', icon: PhoneIcon },
]

const quotes = [
  {
    name: 'Building Materials Quote',
    description: '',
    href: '/building-materials-quote',
    icon: ChartPieIcon,
  },
  {
    name: 'Ready Mix Quote',
    description: '',
    href: '/ready-mix-quote',
    icon: CursorArrowRaysIcon,
  },
]

const quoteCallsToAction = [
  {
    name: 'Contact sales to Request a Quote',
    href: 'tel:346-396-0016',
    icon: PhoneIcon,
  },
]

const company = [
  { name: 'About us', href: '/about' },
  { name: 'Meet our team', href: '/team' },
  { name: 'Customer support', href: '/support' },
  { name: 'Contact us', href: '/contact' },
  // { name: 'Blog', href: '#' },
]

export function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="sticky top-0 z-40 border-b border-blue-brand border-opacity-20 bg-white">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex">
          <a href="." className="-m-1.5 p-1.5">
            <span className="sr-only">Solocrete Supply</span>
            <img
              className="my-4 h-8 w-auto"
              src={logoSolocrete}
              alt="Solocrete Supply"
            />
          </a>
        </div>
        <div className="flex xl:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>

        <div className="hidden xl:-ml-48 xl:flex">
          <PopoverGroup className="hidden xl:mx-4 xl:flex xl:flex-1 xl:justify-center xl:gap-x-8">
            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 text-base font-bold leading-6 text-blue-brand outline-none">
                Building Materials
                <ChevronDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-none text-gray-400"
                />
              </PopoverButton>

              <PopoverPanel
                transition
                // className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                className="absolute -left-6 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg px-2 py-3 text-base leading-6 hover:bg-gray-50"
                    >
                      {/* <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon
                        aria-hidden="true"
                        className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                      />
                    </div> */}
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="text-brand-black block font-semibold"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {materialCallsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-5 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-gray-400"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>

            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 text-base font-bold leading-6 text-blue-brand outline-none">
                Concrete
                <ChevronDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-none text-gray-400"
                />
              </PopoverButton>

              <PopoverPanel
                transition
                // className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                className="absolute -left-6 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="p-4">
                  {concrete.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg px-2 py-3 text-base leading-6 hover:bg-gray-50"
                    >
                      {/* <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon
                        aria-hidden="true"
                        className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                      />
                    </div> */}
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="text-brand-black block font-semibold"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {concreteCallsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-5 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon
                        aria-hidden="true"
                        className="h-5 w-5 flex-none text-gray-400"
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>

            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 text-base font-bold leading-6 text-blue-brand outline-none">
                Request a Quote
                <ChevronDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-none text-gray-400"
                />
              </PopoverButton>

              <PopoverPanel
                transition
                className="absolute -left-6 top-full z-10 mt-3 w-screen max-w-xs overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="p-4">
                  {quotes.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg px-2 py-3 text-base leading-6 hover:bg-gray-50"
                    >
                      {/* <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon
                        aria-hidden="true"
                        className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                      />
                    </div> */}
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="text-brand-black block font-semibold"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>

            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 text-base font-bold leading-6 text-blue-brand outline-none">
                Company
                <ChevronDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-none text-gray-400"
                />
              </PopoverButton>

              <PopoverPanel
                transition
                className="absolute -left-6 top-full z-10 mt-3 w-screen max-w-xs rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="p-3">
                  {company.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg px-2 py-3 text-base leading-6 hover:bg-gray-50"
                    >
                      <div className="flex-auto">
                        <a
                          href={item.href}
                          className="text-brand-black block font-semibold"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                      </div>
                      {/* <a
                    key={item.name}
                    href={item.href}
                    className="text-brand-black block block rounded-lg px-3 py-2 text-base font-semibold leading-6 hover:bg-gray-50"
                  >
                    {item.name}
                  </a> */}
                    </div>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>
          </PopoverGroup>
        </div>

        <div className="hidden w-auto xl:flex xl:items-center xl:justify-end xl:gap-x-12">
          {/* <a
            target="_blank"
            href="https://devsolocretesupply.medusajs.app/"
            className="rounded-lg bg-orange-brand bg-opacity-90 px-3 py-1.5 text-sm font-bold leading-6 text-white hover:bg-opacity-100"
            rel="noreferrer"
          >
            Shop now <span aria-hidden="true">&rarr;</span>
          </a> */}
          {/* <LanguageSelector /> */}
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="2xl:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="." className="-m-1.5 p-1.5 ">
              <span className="sr-only sm:hidden">Solocrete Supply</span>
              <img
                alt="Solocrete Supply"
                src={logoSolocrete}
                className="h-8 w-auto sm:hidden"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Building Materials
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...products, ...materialCallsToAction].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Concrete
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...concrete, ...concreteCallsToAction].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Request a Quote
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...quotes].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                    Company
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {company.map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
              </div>
              {/* <div className="py-6">
                <LanguageSelector />
              </div> */}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}

// // Modules
// import clsx from 'clsx'
// import { Fragment } from 'react'
// import { Link } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'
// import { Popover, Transition } from '@headlessui/react'

// // Components
// import { Button } from './button'
// import { Container } from './container'
// import LanguageSelector from './languageselector'
// // import logoSolocrete from '../images/logos/logo.svg'
// import logoSolocrete from '../images/logos/solocrete-supply.svg'

// function MobileNavLink({
//   href,
//   children,
// }: {
//   href: string
//   children: React.ReactNode
// }) {
//   return (
//     <Popover.Button as={Link} to={href} className="block w-full p-2">
//       {children}
//     </Popover.Button>
//   )
// }

// function MobileNavIcon({ open }: { open: boolean }) {
//   return (
//     <svg
//       aria-hidden="true"
//       className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
//       fill="none"
//       strokeWidth={2}
//       strokeLinecap="round"
//     >
//       <path
//         d="M0 1H14M0 7H14M0 13H14"
//         className={clsx(
//           'origin-center transition',
//           open && 'scale-90 opacity-0',
//         )}
//       />
//       <path
//         d="M2 2L12 12M12 2L2 12"
//         className={clsx(
//           'origin-center transition',
//           !open && 'scale-90 opacity-0',
//         )}
//       />
//     </svg>
//   )
// }

// function MobileNavigation() {
//   const { t } = useTranslation(['header'])

//   return (
//     <Popover>
//       <Popover.Button
//         className="relative z-50 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
//         aria-label="Toggle Navigation"
//       >
//         {({ open }) => <MobileNavIcon open={open} />}
//       </Popover.Button>
//       <Transition.Root>
//         <Transition.Child
//           as={Fragment}
//           enter="duration-150 ease-out"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="duration-150 ease-in"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
//         </Transition.Child>
//         <Transition.Child
//           as={Fragment}
//           enter="duration-150 ease-out"
//           enterFrom="opacity-0 scale-95"
//           enterTo="opacity-100 scale-100"
//           leave="duration-100 ease-in"
//           leaveFrom="opacity-100 scale-100"
//           leaveTo="opacity-0 scale-95"
//         >
//           <Popover.Panel
//             as="div"
//             className="absolute inset-x-0 top-full z-50 mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
//           >
//             <MobileNavLink href="/about">{t('about-us')}</MobileNavLink>
//             <MobileNavLink href="/contact">{t('contact-us')}</MobileNavLink>
//             <hr className="m-2 border-slate-300/40" />
//             <MobileNavLink href="/register">
//               {t('wait-list-button')}
//             </MobileNavLink>
//           </Popover.Panel>
//         </Transition.Child>
//       </Transition.Root>
//     </Popover>
//   )
// }

// export function Header() {
//   const { t } = useTranslation(['header'])

//   return (
//     <header className="sticky top-0 z-50 h-24 border-b border-slate-100 bg-white py-3 shadow-sm">
//       {/* <Container> */}
//       <div className="bg-red-00 mx-auto px-4 sm:px-6 lg:px-8">
//         <nav className="relative flex justify-between">
//           <div className="flex gap-x-12">
//             <div className="flex h-auto flex-row items-center justify-center">
//               <Link to="/" aria-label="Home">
//                 <img
//                   className="my-4 h-10 w-auto"
//                   src={logoSolocrete}
//                   alt="Solocrete Supply"
//                 />
//               </Link>
//             </div>
//           </div>
//           <div className="mb-2 flex gap-x-12">
//             <div className="flex items-center gap-x-1 text-base sm:gap-x-6">
//               <div className="mt-2 hidden font-bold tracking-wider lg:flex lg:gap-x-14">
//                 <Link
//                   className="inline-block rounded-lg px-4 py-1 uppercase text-blue-brand hover:bg-blue-100 hover:text-blue-900"
//                   // className="inline-block rounded-lg px-4 py-1 uppercase text-black hover:bg-black hover:text-white"
//                   to="/products"
//                 >
//                   Products
//                 </Link>
//                 <Link
//                   className="inline-block rounded-lg px-4 py-1 uppercase text-blue-brand hover:bg-blue-100 hover:text-blue-900"
//                   // className="inline-block rounded-lg px-4 py-1 uppercase text-black hover:bg-black hover:text-white"
//                   to="/resources"
//                 >
//                   Resources
//                 </Link>
//                 <Link
//                   className="inline-block rounded-lg px-4 py-1 uppercase text-blue-brand hover:bg-blue-100 hover:text-blue-900"
//                   // className="inline-block rounded-lg px-4 py-1 uppercase text-black hover:bg-black hover:text-white"
//                   to="/quote"
//                 >
//                   Quote Request
//                 </Link>
//                 <Link
//                   className="inline-block rounded-lg px-4 py-1 uppercase text-blue-brand hover:bg-blue-100 hover:text-blue-900"
//                   // className="inline-block rounded-lg px-4 py-1 uppercase text-black hover:bg-black hover:text-white"
//                   to="/faq"
//                 >
//                   FAQ
//                 </Link>
//                 <Link
//                   className="inline-block rounded-lg px-4 py-1 uppercase text-blue-brand hover:bg-blue-100 hover:text-blue-900"
//                   // className="inline-block rounded-lg px-4 py-1 uppercase text-black hover:bg-black hover:text-white"
//                   to="/about"
//                 >
//                   {t('about-us')}
//                 </Link>
//                 <Link
//                   className="inline-block rounded-lg px-4 py-1 uppercase text-blue-brand hover:bg-blue-100 hover:text-blue-900"
//                   // className="inline-block rounded-lg px-4 py-1 uppercase text-black hover:bg-black hover:text-white"
//                   to="/contact"
//                 >
//                   {t('contact-us')}
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className="flex items-center gap-x-2 sm:gap-x-5">
//             {/* <div className="hidden lg:flex">
//               <Button to="/register" color="blue">
//                 {t('wait-list-button')}
//               </Button>
//             </div> */}
//             <LanguageSelector />
//             <div className="-mr-1 lg:hidden">
//               <MobileNavigation />
//             </div>
//           </div>
//         </nav>
//       </div>
//     </header>
//   )
// }
