// Modules
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import QuoteReview from '../images/assets/quote-review.jpg'

const ConcreteQuoteForm = () => {
  // Translation Hook
  const { t } = useTranslation(['concrete-quote'])

  // Effect Hook
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        ;(window as any).hbspt.forms.create({
          region: 'na1',
          portalId: '44496538',
          formId: 'e90f7375-dc71-4bfb-835d-076bb5daee32',
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Solocrete Supply Quote Form"
        description="Solocrete Supply Quote Form"
        name="Solocrete Supply Quote Form"
        type="form"
      />
      <Header />
      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-8">
          <h1 className="text-3xl font-bold tracking-wide">
            Concrete Quote Request Form
          </h1>
          <img
            className="w-auto rounded-lg object-cover sm:h-96 md:h-96"
            src={QuoteReview}
            alt="Quote Review"
          />
          {/* <div className="font-normal text-gray-700">{t('mission')}</div>
          <div className="border-b border-slate-300 lg:my-0"></div> */}
          <div id="hubspotForm"></div>
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default ConcreteQuoteForm
