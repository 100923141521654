// import { ChevronRightIcon } from '@heroicons/react/20/solid'
// import EmblaCarousel from 'embla-carousel'
import rebar from '../images/assets/rebar.jpg'

export function Hero() {
  return (
    <div className="bg-green-brand">
      <div className="relative isolate overflow-hidden">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-10">
          <div className="px-0 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-xl leading-8 tracking-wide">
                <h2 className="text-black mx-8 mt-8 text-4xl font-bold sm:text-5xl md:mx-0">
                  Building Materials & Ready-Mix Concrete together in one place
                </h2>
                <p className="m-8 text-lg text-gray-brand sm:text-xl md:mx-0 md:my-16">
                  Solocrete Supply is your one-stop source for all your concrete
                  needs, offering the best prices and service to ensure your job
                  is done right.
                </p>
                <div className="m-8 flex items-center gap-x-6 md:mx-0 md:my-24">
                  <a
                    href="tel:346-396-0016"
                    className="rounded-lg border border-orange-brand bg-orange-brand bg-opacity-90 px-4 py-2 text-lg font-semibold text-white hover:bg-opacity-100"
                  >
                    Contact sales today
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-12 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-blue-brand shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="scale-90 rounded-3xl shadow-lg">
              {/* <div className="bg-blue-brand [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]"> */}
              <div className="[clip-path:inset(0)] [clip-path:inset(0_round_theme(borderRadius.3xl))]">
                {/* <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-0"
                  aria-hidden="true"
                /> */}
                {/* <div className="relative px-6 pt-8 sm:pt-16 sm:pt-16 md:pl-16 lg:px-0 lg:pt-0"> */}
                <div className="relative">
                  {/* <div className="mx-auto max-w-xl md:mx-0 md:max-w-none"> */}
                  <div className="mx-auto">
                    <img
                      className="h-auto w-auto"
                      // src="https://media.gettyimages.com/id/1137368159/photo/close-up-of-some-wood-in-the-workshop.jpg?s=2048x2048&w=gi&k=20&c=des2TfNAOhhs59mqoP2taN4VYIUPkI0moKv7sRn4W0s="
                      src={rebar}
                      alt="Rebar"
                    />

                    {/* <div className="w-screen overflow-hidden rounded-tl-xl bg-gray-900">
                      <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                        <div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">
                          <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                            NotificationSetting.jsx
                          </div>
                          <div className="border-r border-gray-600/10 px-4 py-2">
                            App.jsx
                          </div>
                        </div>
                      </div>
                      <div className="px-6 pb-14 pt-6">
                      </div>
                    </div> */}
                  </div>
                  <div
                    // className="ring-black/10 pointer-events-none absolute inset-0 ring-1 ring-inset md:rounded-3xl"
                    className="ring-black/10 pointer-events-none absolute md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-1" />
      </div>
    </div>
  )
}

// // Components
// import EmblaCarousel from './emblacarousel'

// // Assets
// import '../css/embla.css'

// // Constants
// const OPTIONS = { loop: true, duration: 60 }
// const SLIDES = [
//   {
//     src: 'https://media.gettyimages.com/id/1137368159/photo/close-up-of-some-wood-in-the-workshop.jpg?s=2048x2048&w=gi&k=20&c=des2TfNAOhhs59mqoP2taN4VYIUPkI0moKv7sRn4W0s=',
//     description: 'Close-up of some wood in the workshop',
//   },
//   {
//     src: 'https://media.gettyimages.com/id/1272998580/photo/steel-rods-at-a-factory.jpg?s=2048x2048&w=gi&k=20&c=hVuoprOYehNMbDo683pxluTpsqzNJ7YHKNRn41X1-6A=',
//     description: 'Steel rods at a factory',
//   },
//   {
//     src: 'https://media.gettyimages.com/id/1175794247/photo/worker-at-the-building-site-is-preparing-construction-frame.jpg?s=2048x2048&w=gi&k=20&c=if4Bkcc2qZv5gtIrYMdJs7kSVbGqdS-VpeP9GFKJaV8=',
//     description: 'Worker at the building site is preparing construction frame',
//   },
// ]

// export function NewHero() {
//   return (
//     <div className="my-1 flex flex-row justify-center sm:my-2 lg:my-4">
//       <EmblaCarousel slides={SLIDES} options={OPTIONS} />
//     </div>
//   )
// }

// // @ts-nocheck
// // Modules
// import { useTranslation } from 'react-i18next';

// // Components
// import Video from "./video";
// import { Button } from './button'
// import { Container } from './container'

// // Assets
// import mutedVideo from "../videos/build.mp4";

// export function Hero() {
//   const { t } = useTranslation(["hero"]);

//   return (
//     <div className='flex flex-row justify-center'>
//       <Video src={mutedVideo} />
//       <Container>
//         <div className='-mb-24 text-center pb-16 pt-16 lg:py-20 xl:py-40'>
//           <h1 className="lg:mt-12 mx-auto max-w-7xl text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-6xl font-medium tracking-normal text-slate-900 drop-shadow-2xl">
//             <span className="relative whitespace-nowrap text-slate-50 [text-shadow:_0_1px_0_rgb(0_0_0_/_100%)]">
//               <svg
//                 aria-hidden="true"
//                 viewBox="0 0 418 42"
//                 className="absolute left-0 top-3/4 h-[0.58em] w-full fill-slate-100/70"
//                 preserveAspectRatio="none"
//                 >
//                 <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
//               </svg>
//               {t("headline_underlined")}{' '}
//             </span>{' '}
//             <div className="relative text-slate-900 [text-shadow:_0_1px_1px_rgb(0_0_0_/_10%)]">{t("headline")}</div>
//           </h1>
//           <p className="relative mx-auto z-40 mt-6 max-w-3xl text-2xl tracking-tight text-slate-900 drop-shadow-xl hidden md:flex [text-shadow:_0_1px_1px_rgb(255_255_255_/_33%)]">
//             {t("subheaderline")}
//           </p>
//           <div className="mt-6 mb-16 lg:my-10 flex justify-center gap-x-6">
//             <Button className='z-50' to="/register">{t("wait-list-button")}</Button>
//           </div>
//         </div>
//       </Container>
//     </div>
//   )
// }
