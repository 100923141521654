// Modules
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Button } from '../components/button'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import fasteners from '../images/assets/fasteners.png'

const Fasteners = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['fasteners'])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Solocrete Supply: Fasteners"
        description="Solocrete Supply is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply: Fasteners"
        type="article"
      />
      <Header />

      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          <h1 className="text-3xl font-bold tracking-wide">
            Fasteners for Concrete Contractors
          </h1>
          <img
            className="h-auto w-auto rounded-lg"
            src={fasteners}
            alt="Fasteners"
          />
          <p className="text-base">
            Fasteners are essential components in construction, particularly for
            concrete projects where durable connections are critical. Concrete
            contractors rely on different types of fasteners to ensure
            structural integrity and efficiency. This page provides an overview
            of the most commonly used fasteners: anchor bolts, nails, and bar
            ties. We'll also discuss their sizes, applications, and how they are
            typically used in various projects.
          </p>

          <h2 className="text-xl font-bold tracking-wide">
            Types of Fasteners and Their Sizes
          </h2>
          <p className="text-base">
            Fasteners come in various types and sizes to suit specific needs in
            concrete construction. The most common fasteners used by concrete
            contractors include:
          </p>

          <ul className="list-inside list-disc space-y-4 text-base">
            <li>
              <strong>Anchor Bolts</strong> – Available in diameters ranging
              from 1/4" to 1-1/2" and lengths from 4" to over 36". These bolts
              are used to attach objects or structures to concrete surfaces.
            </li>
            <li>
              <strong>Nails</strong> – Typically ranging in size from 1" to 6"
              in length, concrete nails are specially designed to penetrate hard
              surfaces like masonry and concrete.
            </li>
            <li>
              <strong>Bar Ties</strong> – Available in sizes ranging from 4" to
              12", these ties are used to secure rebar in place, helping to
              reinforce concrete structures.
            </li>
          </ul>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">Anchor Bolts</h2>
            <p className="text-base">
              Anchor bolts are fasteners that are used to secure structural
              elements to concrete. They are critical in many projects, from
              large-scale commercial buildings to smaller residential
              constructions.
            </p>

            <h3 className="text-lg font-bold tracking-wide">
              Sizes and Specifications
            </h3>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>Diameters: 1/4" to 1-1/2"</li>
              <li>Lengths: 4" to over 36"</li>
              <li>
                Materials: Typically made from steel and often coated with zinc
                for corrosion resistance
              </li>
            </ul>

            <h3 className="text-lg font-bold tracking-wide">Use Cases</h3>
            <p className="text-base">
              Anchor bolts are used in various scenarios, such as:
            </p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                Securing structural steel columns, beams, and plates to concrete
                foundations
              </li>
              <li>
                Attaching light poles, signposts, and fences to concrete
                surfaces
              </li>
              <li>Installing heavy machinery or equipment on concrete pads</li>
            </ul>
            <h3 className="text-lg font-bold tracking-wide">
              How Concrete Contractors Use Anchor Bolts
            </h3>
            <p className="text-base">
              Concrete contractors typically install anchor bolts during the
              pouring phase of a concrete foundation. The bolts are embedded
              into the wet concrete and positioned according to precise
              measurements to ensure they align with the structure or equipment
              that will be attached later. Contractors may also use epoxy-based
              anchor systems for post-installed applications.
            </p>
          </div>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">Nails</h2>
            <p className="text-base">
              Concrete nails are specially designed with hardened steel and
              ridges to penetrate dense concrete and masonry surfaces. They are
              typically used where screws or bolts would be excessive but a
              strong bond is still required.
            </p>

            <h3 className="text-lg font-bold tracking-wide">
              Sizes and Specifications
            </h3>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>Lengths: 1" to 6"</li>
              <li>
                Materials: Hardened steel, sometimes galvanized for rust
                resistance
              </li>
            </ul>

            <h3 className="text-lg font-bold tracking-wide">Use Cases</h3>
            <p className="text-base">Common uses of concrete nails include:</p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>Attaching wood framing to concrete floors or walls</li>
              <li>
                Securing furring strips, metal brackets, or conduit to masonry
              </li>
              <li>
                Fastening temporary supports or forms to concrete during
                construction
              </li>
            </ul>
            <h3 className="text-lg font-bold tracking-wide">
              How Concrete Contractors Use Nails
            </h3>
            <p className="text-base">
              Concrete contractors use a hammer or nail gun specifically
              designed for masonry applications to drive concrete nails into
              surfaces. These nails are often used to secure formwork or attach
              wooden structures to concrete foundations, walls, or slabs.
            </p>
          </div>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">Bar Ties</h2>
            <p className="text-base">
              Bar ties, also known as rebar ties, are used to secure rebar
              (reinforcing bars) in place within a concrete structure. They help
              ensure that the rebar stays in position as concrete is poured,
              which is critical for the structural integrity of the project.
            </p>

            <h3 className="text-lg font-bold tracking-wide">
              Sizes and Specifications
            </h3>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                Sizes: 4" to 12" (depending on rebar size and application)
              </li>
              <li>Materials: Soft, flexible wire, typically made of steel</li>
            </ul>

            <h3 className="text-lg font-bold tracking-wide">Use Cases</h3>
            <p className="text-base">
              Bar ties are essential for reinforcing concrete in projects such
              as:
            </p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>Foundations and slabs</li>
              <li>Columns and beams</li>
              <li>Bridges and highway structures</li>
            </ul>
            <h3 className="text-lg font-bold tracking-wide">
              How Concrete Contractors Use Bar Ties
            </h3>
            <p className="text-base">
              Concrete contractors use bar ties to secure rebar intersections
              before the concrete is poured. They typically use a special tying
              tool, such as a rebar tier or pliers, to quickly twist the ties
              and hold the rebar in place. Proper rebar placement and secure
              tying are crucial for the concrete to achieve the desired strength
              and durability.
            </p>
          </div>

          <h2 className="text-xl font-bold tracking-wide">Conclusion</h2>
          <p className="text-base">
            Fasteners such as anchor bolts, nails, and bar ties play an
            essential role in concrete construction. Each fastener type serves
            specific purposes, whether securing structural elements, attaching
            materials to concrete, or reinforcing rebar. Concrete contractors
            rely on these fasteners to ensure their projects are built safely,
            securely, and according to design specifications.
          </p>
        </main>
      </div>

      <Footer />
    </div>
  )
}

export default Fasteners
