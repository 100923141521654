// Modules
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Button } from '../components/button'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import rebar from '../images/assets/rebar.webp'

const SteelProducts = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['steelproducts'])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Solocrete Supply: Rebar And Accessories"
        description="Solocrete Supply is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply: Rebar And Accessories"
        type="article"
      />
      <Header />

      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          <div className="flex flex-col gap-y-8">
            <h1 className="text-3xl font-bold tracking-wide">
              {t('steel-products-h1')}
            </h1>
            <img
              className="w-auto rounded-lg object-cover sm:h-96 md:h-96"
              src={rebar}
              alt="Rebar"
            />
          </div>
          <div className="flex flex-col gap-y-8">
            <p className="text-base">
              Rebar, or reinforcing bar, is a steel bar or mesh of steel wires
              used as a tension device in reinforced concrete and masonry
              structures to strengthen and hold the concrete in compression.
              Rebar is essential in construction projects as it enhances the
              tensile strength of concrete, which is weak in tension. This
              combination of rebar and concrete creates structures that can
              withstand heavy loads, weather, and time, making them ideal for a
              wide range of applications from residential buildings to large
              infrastructure projects like bridges and highways.
            </p>
            <p className="text-base">
              Rebar is available in a variety of lengths, diameters, grades, and
              finishes to meet the specific needs of any construction project.
              The most common sizes range from #3 to #18 in diameter, with
              standard lengths typically available in 20, 30, 40, or 60 feet.
              Custom lengths and fabricated shapes are also available.
            </p>
          </div>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">
              {t('steel-products-h2-1')}
            </h2>
            <p className="text-base">{t('steel-products-h2-1-p')}</p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <div className="mb-4 text-lg font-bold">
                {t('steel-products-h2-1-l1-h1')}
              </div>
              <li>
                <b>{t('steel-products-h2-1-l1-li1-b')}</b>{' '}
                {t('steel-products-h2-1-l1-li1')}
              </li>
              <li>
                <b>{t('steel-products-h2-1-l1-li2-b')}</b>{' '}
                {t('steel-products-h2-1-l1-li2')}
              </li>
              <li>
                <b>{t('steel-products-h2-1-l1-li3-b')}</b>{' '}
                {t('steel-products-h2-1-l1-li3')}
              </li>
              <li>
                <b>{t('steel-products-h2-1-l1-li4-b')}</b>{' '}
                {t('steel-products-h2-1-l1-li4')}
              </li>
            </ul>

            <ul className="list-inside list-disc space-y-4 text-base">
              <div className="mb-4 text-lg font-bold">
                {t('steel-products-h2-1-l2-h1')}
              </div>
              <li>
                <b>{t('steel-products-h2-1-l2-li1-b')}</b>{' '}
                {t('steel-products-h2-1-l2-li1')}
              </li>
              <li>
                <b>{t('steel-products-h2-1-l2-li2-b')}</b>{' '}
                {t('steel-products-h2-1-l2-li2')}
              </li>
              <li>
                <b>{t('steel-products-h2-1-l2-li3-b')}</b>{' '}
                {t('steel-products-h2-1-l2-li3')}
              </li>
              <li>
                <b>{t('steel-products-h2-1-l2-li4-b')}</b>{' '}
                {t('steel-products-h2-1-l2-li4')}
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">
              {t('steel-products-h2-2')}
            </h2>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                <b>{t('steel-products-h2-2-l1-li1-b')}</b>{' '}
                {t('steel-products-h2-2-l1-li1')}
              </li>
              <li>
                <b>{t('steel-products-h2-2-l1-li2-b')}</b>{' '}
                {t('steel-products-h2-2-l1-li2')}
              </li>
              <li>
                <b>{t('steel-products-h2-2-l1-li3-b')}</b>{' '}
                {t('steel-products-h2-2-l1-li3')}
              </li>
              <li>
                <b>{t('steel-products-h2-2-l1-li4-b')}</b>{' '}
                {t('steel-products-h2-2-l1-li4')}
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">
              {t('steel-products-h2-3')}
            </h2>
            <p className="text-base">{t('steel-products-h2-3-p')}</p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                <b>{t('steel-products-h2-3-l1-li1-b')}</b>{' '}
                {t('steel-products-h2-3-l1-li1')}
              </li>
              <li>
                <b>{t('steel-products-h2-3-l1-li2-b')}</b>{' '}
                {t('steel-products-h2-3-l1-li2')}
              </li>
              <li>
                <b>{t('steel-products-h2-3-l1-li3-b')}</b>{' '}
                {t('steel-products-h2-3-l1-li3')}
              </li>
              <li>
                <b>{t('steel-products-h2-3-l1-li4-b')}</b>{' '}
                {t('steel-products-h2-3-l1-li4')}
              </li>
              <li>
                <b>{t('steel-products-h2-3-l1-li5-b')}</b>{' '}
                {t('steel-products-h2-3-l1-li5')}
              </li>
              <li>
                <b>{t('steel-products-h2-3-l1-li6-b')}</b>{' '}
                {t('steel-products-h2-3-l1-li6')}
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">
              {t('steel-products-h2-4')}
            </h2>
            <p className="text-base">{t('steel-products-h2-4-p')}</p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                <b>{t('steel-products-h2-4-l1-li1-b')}</b>{' '}
                {t('steel-products-h2-4-l1-li1')}
              </li>
              <li>
                <b>{t('steel-products-h2-4-l1-li2-b')}</b>{' '}
                {t('steel-products-h2-4-l1-li2')}
              </li>
              <li>
                <b>{t('steel-products-h2-4-l1-li3-b')}</b>{' '}
                {t('steel-products-h2-4-l1-li3')}
              </li>
              <li>
                <b>{t('steel-products-h2-4-l1-li4-b')}</b>{' '}
                {t('steel-products-h2-4-l1-li4')}
              </li>
              <li>
                <b>{t('steel-products-h2-4-l1-li5-b')}</b>{' '}
                {t('steel-products-h2-4-l1-li5')}
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-y-8 text-lg">
            <h2 className="text-xl font-bold tracking-wide">
              {t('steel-products-h2-5')}
            </h2>
            <p className="text-base">{t('steel-products-h2-5-p')}</p>
            <ul className="list-inside list-disc space-y-4 text-base">
              <li>
                <b>{t('steel-products-h2-5-l1-li1-b')}</b>{' '}
                {t('steel-products-h2-5-l1-li1')}
              </li>
              <li>
                <b>{t('steel-products-h2-5-l1-li2-b')}</b>{' '}
                {t('steel-products-h2-5-l1-li2')}
              </li>
              <li>
                <b>{t('steel-products-h2-5-l1-li3-b')}</b>{' '}
                {t('steel-products-h2-5-l1-li3')}
              </li>
              <li>
                <b>{t('steel-products-h2-5-l1-li4-b')}</b>{' '}
                {t('steel-products-h2-5-l1-li4')}
              </li>
              <li>
                <b>{t('steel-products-h2-5-l1-li5-b')}</b>{' '}
                {t('steel-products-h2-5-l1-li5')}
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-y-8 text-base">
            <p>{t('steel-products-h2-closing')}</p>
          </div>
        </main>
      </div>

      <Footer />
    </div>
  )
}

export default SteelProducts
