// Modules
import { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, Routes } from 'react-router-dom'

// Components
import Home from './pages/home'

// Products
import SteelProducts from './pages/steel-products'
import LumberPlywood from './pages/lumber-plywood'
import Fasteners from './pages/fasteners'
import SafetySupplies from './pages/safety-supplies'
import Waterproofing from './pages/waterproofing'

// Ready Mix Concrete
import Concrete from './pages/concrete'
import BaggedConcrete from './pages/bagged'

// Quote Form
import BuildingMaterialsQuoteForm from './pages/building-materials-quote'
import ConcreteQuoteForm from './pages/concrete-quote'

// Credit Application
// import HubspotCreditForm from './pages/credit'

// Company
import AboutUs from './pages/about'
import Team from './pages/team'
import SupportForm from './pages/support'
import ContactUs from './pages/contact'
import Terms from './pages/terms'
import Privacy from './pages/privacy'
import Shipping from './pages/shipping'
import Returns from './pages/returns'
import Disclaimer from './pages/disclaimer'

export default function App() {
  return (
    //   <Suspense fallback={<Loader />}>
    <Suspense fallback={''}>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
            default-src 'self';
            script-src 'self' 'unsafe-inline' 'unsafe-eval' https://forms.hsforms.com https://js.hsforms.net https://js.hs-banner.com https://js.usemessages.com https://js.hs-analytics.net https://js.hscollectedforms.net https://picsum.photos https://app.termly.io;
            connect-src 'self' https://forms.hsforms.com https://js.hsforms.net https://vitals.vercel-insights.com https://api.getwaitlist.com https://js.hs-banner.com https://js.usemessages.com https://js.hs-analytics.net https://js.hscollectedforms.net https://forms.hscollectedforms.net https://track.hubspot.com https://app.hubspot.com https://api.hubspot.com https://picsum.photos;
            font-src fonts.gstatic.com;
            img-src 'self' https://*.unsplash.com/ https://*.hubspotusercontent-na1.net https://*.hsforms.com/ https://track.hubspot.com https://forms.hsforms.com https://picsum.photos https://fastly.picsum.photos https://media.gettyimages.com;
            frame-src 'self' https://app.hubspot.com https://app.termly.io;
            style-src 'self' 'unsafe-inline' fonts.googleapis.com sha256-DnaqpZzZnNOTsPfM/jmZ/lzX6hF+6P856AFp5oXYGFI=;
            media-src 'self' https://media.istockphoto.com;
          `}
        ></meta>
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="team" element={<Team />} />
        <Route path="ready-mix-concrete" element={<Concrete />} />
        <Route path="bagged-concrete" element={<BaggedConcrete />} />
        <Route path="rebar-and-accessories" element={<SteelProducts />} />
        <Route path="steel-products" element={<SteelProducts />} />
        <Route path="lumber-and-plywood" element={<LumberPlywood />} />
        <Route path="fasteners" element={<Fasteners />} />
        <Route path="safety-supplies" element={<SafetySupplies />} />
        <Route path="waterproofing" element={<Waterproofing />} />
        <Route path="contact" element={<ContactUs />} />
        <Route
          path="building-materials-quote"
          element={<BuildingMaterialsQuoteForm />}
        />
        <Route path="ready-mix-quote" element={<ConcreteQuoteForm />} />
        <Route path="support" element={<SupportForm />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="shipping" element={<Shipping />} />
        <Route path="returns" element={<Returns />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        {/* <Route path="credit" element={<HubspotCreditForm />} /> */}
      </Routes>
    </Suspense>
  )
}
