// Modules
import { useTranslation } from 'react-i18next'

// Components
import SEO from '../components/seo'
import { Button } from '../components/button'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

// Assets
import sheeting from '../images/assets/sheeting.png'

const Waterproofing = (): JSX.Element => {
  // Translation Hook
  const { t } = useTranslation(['waterproofing'])

  return (
    <div className="min-h-screen bg-gray-brand">
      <SEO
        title="Contact Solocrete Supply"
        description="Solocrete is your single source for material take-off quoting, online ordering, and scheduling of pickups or deliveries."
        name="Solocrete Supply"
        type="article"
      />
      <Header />

      <div className="mx-auto w-full px-4 sm:px-8 md:max-w-7xl">
        <main className="my-12 flex flex-col gap-y-12">
          {/* <!-- Page Title --> */}
          <h1 className="mb-0 text-3xl font-bold">
            Waterproofing for Concrete Contractors
          </h1>
          <img
            className="w-auto rounded-lg object-cover sm:h-96 md:h-96"
            src={sheeting}
            alt="Sheeting"
          />
          <p className="mb-0">
            Effective waterproofing is essential for concrete construction
            projects to protect against moisture infiltration and structural
            damage. Concrete contractors rely on various waterproofing
            materials, including vapor barriers and mastics, to create durable,
            moisture-resistant structures. This page provides an overview of
            these key waterproofing products, their sizes, use cases, and common
            applications in construction projects.
          </p>

          {/* <!-- Types of Waterproofing and Sizes Section --> */}
          <section className="mb-0">
            <h2 className="mb-10 text-2xl font-semibold">
              Types of Waterproofing Materials and Their Sizes
            </h2>
            <p className="mb-4">
              Waterproofing products for concrete projects vary based on the
              application needs. The most commonly used products include:
            </p>
            <ul className="list-inside list-disc space-y-2">
              <li>
                <span className="font-semibold">Vapor Barriers</span> –
                Available in sheets or rolls, typically between 6 and 20 mil
                thicknesses, used to prevent moisture from penetrating concrete
                slabs.
              </li>
              <li>
                <span className="font-semibold">Mastics</span> – Sold in buckets
                or cartridges, with options for liquid, paste, or spray
                applications, used to seal and waterproof joints, cracks, and
                seams in concrete structures.
              </li>
            </ul>
          </section>

          {/* <!-- Vapor Barriers Section --> */}
          <section className="mb-0">
            <h2 className="mb-8 text-2xl font-semibold">Vapor Barriers</h2>
            <p className="mb-4">
              Vapor barriers are essential for controlling moisture and vapor
              transmission through concrete slabs. They are typically installed
              beneath concrete floors or slabs to protect against ground
              moisture, which can cause cracks, mold, and other structural
              issues.
            </p>

            <h3 className="mb-2 text-lg font-semibold">
              Sizes and Specifications
            </h3>
            <ul className="mb-4 list-inside list-disc space-y-1">
              <li>Thicknesses: 6 mil to 20 mil</li>
              <li>
                Available in rolls for easy installation across large areas
              </li>
              <li>
                Made from polyethylene or other synthetic materials for
                durability and resistance
              </li>
            </ul>

            <h3 className="mb-2 text-lg font-semibold">Use Cases</h3>
            <ul className="mb-4 list-inside list-disc space-y-1">
              <li>
                Under concrete slabs to prevent ground moisture infiltration
              </li>
              <li>In basement floors to protect against mold and mildew</li>
              <li>
                Below grade or subgrade applications where moisture control is
                critical
              </li>
            </ul>

            <h3 className="mb-2 text-lg font-semibold">
              How Concrete Contractors Use Vapor Barriers
            </h3>
            <p className="mb-0">
              Concrete contractors install vapor barriers by rolling them out
              across the prepared ground surface before pouring the concrete
              slab. The sheets are overlapped and sealed at the seams to create
              a continuous moisture barrier. Proper placement and sealing are
              essential to prevent any water or vapor from penetrating the
              concrete structure, ensuring a stable and dry environment.
            </p>
          </section>

          {/* <!-- Mastics Section --> */}
          <section className="mb-0">
            <h2 className="mb-8 text-2xl font-semibold">Mastics</h2>
            <p className="mb-4">
              Mastics are versatile waterproofing materials commonly used for
              sealing joints, cracks, and seams in concrete structures.
              Available in paste, liquid, or spray forms, mastics offer
              excellent adhesion and flexibility to withstand structural
              movement.
            </p>

            <h3 className="mb-2 text-lg font-semibold">
              Sizes and Specifications
            </h3>
            <ul className="mb-4 list-inside list-disc space-y-1">
              <li>
                Available in 1-gallon, 5-gallon buckets, or cartridges for easy
                application
              </li>
              <li>Options for brush, trowel, or spray applications</li>
              <li>
                Made from rubberized or bituminous compounds for durability and
                flexibility
              </li>
            </ul>

            <h3 className="mb-2 text-lg font-semibold">Use Cases</h3>
            <ul className="mb-4 list-inside list-disc space-y-1">
              <li>
                Sealing joints and seams in concrete walls, floors, and roofs
              </li>
              <li>
                Filling gaps around pipes and other penetrations in concrete
                surfaces
              </li>
              <li>
                Waterproofing foundation walls, retaining walls, and similar
                structures
              </li>
            </ul>

            <h3 className="mb-2 text-lg font-semibold">
              How Concrete Contractors Use Mastics
            </h3>
            <p className="mb-0">
              Concrete contractors typically apply mastics to specific areas
              requiring waterproofing. For example, they may use a trowel or
              brush to apply mastic along foundation wall joints or around
              utility penetrations. The flexibility of mastics allows them to
              handle structural movement, making them ideal for areas that may
              experience expansion or contraction.
            </p>
          </section>

          {/* <!-- Conclusion Section --> */}
          <section className="mb-0">
            <h2 className="mb-8 text-2xl font-semibold">Conclusion</h2>
            <p>
              Vapor barriers and mastics are essential waterproofing solutions
              in concrete construction, each offering specific benefits to
              protect against moisture. By understanding the applications of
              these materials, concrete contractors can ensure their projects
              are durable, structurally sound, and resilient against water
              damage.
            </p>
          </section>

          {/* <div className="flex flex-col gap-y-8">
            <h1 className="text-3xl font-bold tracking-wide">
              {t('steel-products-header')}
            </h1>
            <img
              className="w-auto rounded-lg object-cover sm:h-96 md:h-96"
              src={rebar}
              alt="Rebar"
            />
          </div>
          <div className="flex flex-col gap-y-8">
            <p className="text-lg">
              Rebar is the structural component of concrete foundations,
              structures, and paving. At RSS, rebar is a huge piece of what we
              do, and we do it really well! Each of our four locations has the
              equipment and man-power on site to produce fabricated rebar in an
              accurate and timely manner to meet you specific needs. And, of
              course, we have straight bar in 20 and 40 footers cut to any
              length you like, and we stock wire mesh sheets. We will deliver to
              your jobsite or yard as needed. RSS will provide estimating,
              detailing, and in-house tracking to ensure deliveries are made as
              scheduled.
            </p>
            <p className="text-lg">
              To make sure the rebar is positioned as per the Engineer's design,
              we stock a complete line of bar supports including chairs, bricks,
              bolsters, spacer wheels, and pier boots. Let us put together a
              complete package including all rebar, mesh, and supports to make
              your job just a little easier. Call us, we can help!
            </p>
          </div>
          <div className="flex flex-col gap-y-8">
            <ul className="list-inside list-disc gap-y-12 text-lg">
              <div className="font-semibold">Straight Bar</div>
              <li className="">#3 to #10</li>
              <li className="">20ft, 40ft, 60ft</li>
            </ul>
          </div>
          <div className="flex flex-col gap-y-8">
            <ul className="list-inside list-disc gap-y-12 text-lg">
              <div className="font-semibold">Fabricated Bar</div>
              <li className="">Stirrups</li>
              <li className="">Corner Bars</li>
              <li className="">Circles</li>
            </ul>
          </div>
          <div className="flex flex-col gap-y-8">
            <ul className="list-inside list-disc gap-y-12 text-lg">
              <div className="font-semibold">Bar Supports</div>
              <li className="">Rebar Chairs</li>
              <li className="">Utility Brick</li>
            </ul>
          </div> */}
        </main>
      </div>

      <Footer />
    </div>
  )
}

export default Waterproofing
